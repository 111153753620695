'use client'
import { siteConfig } from "@/config/site"
import { MainNav } from "@/components/main-nav"
import { ThemeToggle } from "@/components/theme-toggle"

import { SheetMenu } from "./sheet-menu"
import { UserNav } from "./user-nav"
import { DigitalClock } from "./custom/digital-clock"
import { Switch } from "./ui/switch"
import { useEffect, useState } from "react"
import { useIsOnline } from "@/api_store/mutations/managers/changeOnlineStatus"
import { useToast } from "./ui/use-toast"
import { useKeycloak } from "@/auth/provider/KeycloakProvider"
import { CustomSwitch } from "./custom/custom-switch"

export function SiteHeader() {
  const [onlineStatus, setOnlineStatus] = useState(false);
  const { toast } = useToast();
  const { user }: any = useKeycloak();
  const userId = user?.userId;

  const { mutate, isPending: isLoadingStatus } = useIsOnline({
    onSuccess: (data) => {
      if (data?.data?.isOnline) {
        setOnlineStatus(data?.data?.isOnline);
      }
      else if (!data?.data?.isOnline) {
        setOnlineStatus(data?.data?.isOnline)
      }
      else {
        toast({
          variant: "destructive",
          className: "bg-red-600 text-white hover:bg-red-700",
          description: "Can't update status! Please contact support.",
        })
      }
    },
    onError: (error) => {
      console.log("Online status error:", error)
    },
  });

  useEffect(() => {
    if (userId?.length > 0) {
      mutate({ userId });
    }
  }, [userId])

  const handleChangeStatus = (action: boolean) => {
    mutate({ userId, isOnline: action })
  }

  return (
    <header className="sticky top-0 z-10 w-full border-b border-border/40 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 ">
      <div className="flex h-16 items-center justify-between px-4 lg:px-8">
        {/* Left section */}
        <div className="flex items-center space-x-4">
          <MainNav items={siteConfig.mainNav} />
        </div>


        {/* Right section */}
        <div className="flex items-center space-x-6">
          <DigitalClock />
          <nav className="flex items-center space-x-6">
            <CustomSwitch
              checked={onlineStatus}
              onCheckedChange={handleChangeStatus}
              label={onlineStatus ? "Online" : "Offline"}
            />
            <ThemeToggle />
            <UserNav />
          </nav>
          <SheetMenu />
        </div>
      </div>
    </header>
  )
}
