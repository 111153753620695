import Link from "next/link"
import { MenuIcon } from "lucide-react"

import { Button } from "@/components/ui/button"
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTrigger,
} from "@/components/ui/sheet"

import { MenuItems } from "./menu-items"

export function SheetMenu() {
  return (
    <Sheet>
      <SheetTrigger className="lg:hidden" asChild>
        <Button className="h-8" variant="outline" size="icon">
          <MenuIcon size={20} />
        </Button>
      </SheetTrigger>
      <SheetContent className="sm:w-72 px-3 h-full flex flex-col" side="left">
        <SheetHeader>
          {/* <Button
            className="flex justify-center items-center pb-2 pt-1"
            variant="link"
            asChild
          >
            
          </Button> */}
        </SheetHeader>
        {/* <MenuItems /> */}
      </SheetContent>
    </Sheet>
  )
}
