"use client"

import { useState, useEffect } from 'react'

function TimeUnit({ value }: { value: number }) {
    return (
        <div className="w-8 flex items-center justify-center">
            <span className="text-base font-bold text-primary">
                {value.toString().padStart(2, '0')}
            </span>
        </div>
    )
}

function AmPmIndicator({ isAm }: { isAm: boolean }) {
    return (
        <div className="ml-0.5 flex items-center justify-center">
            <span className="text-xs font-semibold">{isAm ? "AM" : "PM"}</span>
        </div>
    )
}

export function DigitalClock() {
    const [time, setTime] = useState(new Date())

    useEffect(() => {
        const timer = setInterval(() => {
            setTime(new Date())
        }, 1000)

        return () => clearInterval(timer)
    }, [])

    const hours = time.getHours()
    const minutes = time.getMinutes()
    const seconds = time.getSeconds()
    const isAm = hours < 12

    const displayHours = hours % 12 || 12 // Convert 0 to 12 for midnight

    return (
        <div className="flex items-center justify-center p-1.5 rounded-md bg-accent/80">
            <TimeUnit value={displayHours} />
            <span className="text-base font-bold text-primary">:</span>
            <TimeUnit value={minutes} />
            <span className="text-base font-bold text-primary">:</span>
            <TimeUnit value={seconds} />
            <AmPmIndicator isAm={isAm} />
        </div>
    )
}