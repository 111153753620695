import { getToken } from "@/auth/config/keycloak";
import { useMutation } from "@tanstack/react-query";

interface UserMutationProps {
    userId: number;
    isOnline?: boolean; // Make action optional
}

export const triggerOnlineStatus = async ({ userId, isOnline }: UserMutationProps) => {
    const token = getToken();
    const options: RequestInit = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    };

    // Conditionally add the body if isOnline is provided
    if (typeof isOnline !== "undefined") {
        options.body = JSON.stringify({ isOnline });
    }

    const response = await fetch(`${process.env.NEXT_PUBLIC_API_SUPPORT_URL}/support/manager/credential/${userId}`, options);

    if (!response.ok) {
        throw { status: response.status, response: response.statusText };
    }

    const data = await response.json();
    return data;
};

interface MutationOptionProps {
    onSuccess?: (data: any) => void;
    onError?: (error: Error) => void;
}

export const useIsOnline = (options: MutationOptionProps = {}) => {
    return useMutation({
        mutationFn: triggerOnlineStatus,
        onSuccess: options.onSuccess,
        onError: options.onError,
        retry: 3, // Retries 3 times on failure (default behavior)
        retryDelay: (attempt) => Math.min(1000 * 2 ** attempt, 20000),
    });
};