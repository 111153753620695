import React from 'react'
import { Switch } from "@/components/ui/switch"
import { Label } from "@/components/ui/label"

interface CustomSwitchProps {
  checked: boolean
  onCheckedChange: (checked: boolean) => void
  label: string
}

export function CustomSwitch({ checked, onCheckedChange, label }: CustomSwitchProps) {
  return (
    <div className="flex items-center space-x-2">
      <Label htmlFor="custom-switch" className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
        {label}
      </Label>
      <Switch
        id="custom-switch"
        checked={checked}
        onCheckedChange={onCheckedChange}
        className="data-[state=checked]:bg-green-500 data-[state=unchecked]:bg-red-500"
      />
    </div>
  )
}