"use client";
import * as React from "react";
import Link from "next/link";

import { NavItem } from "@/types/nav";
import { cn } from "@/lib/utils";
import { useTheme } from "next-themes";

interface MainNavProps {
  items?: NavItem[]
}

export function MainNav({ items }: MainNavProps) {
  const { theme } = useTheme();
  return (
    <div className="flex gap-6">
      <Link href="/" className="flex items-center space-x-2">
        <img src={theme === "dark" ? "/images/dream-emirates-logo-dark.png" : "/images/dream-emirates-logo.png"} className="w-[100px]" />
        {/* Dream Emirates */}
      </Link>
      {items?.length ? (
        <nav className="flex gap-6">
          {items?.map(
            (item, index) =>
              item.href && (
                <Link
                  key={index}
                  href={item.href}
                  className={cn(
                    "flex items-center text-sm font-medium text-muted-foreground",
                    item.disabled && "cursor-not-allowed opacity-80"
                  )}
                >
                  {item.title}
                </Link>
              )
          )}
        </nav>
      ) : null}
    </div>
  )
}
